import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import * as Markdown from 'react-markdown'
import colors from '../styles/colors'
import Button from './button'
import ContactForm from './contact-form'
import CardGeneral from './card-general'
import { Star } from 'react-feather'

const ServiceHero = ({ pageData }) => {
  const FORMOFFSETPX = 350

  const ContentContainer = styled.div``
  const HeadingThree = styled.h3`
    margin-bottom: 0.25em;
  `

  const ContentRow = styled.div``

  const ContentCol = styled.div`
    padding-top: 130px;
    padding-bottom: 60px;
    padding-right: 45px;
    color: #fff;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    @media (max-width: 767px) {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 0px;
    }
  `

  const HeroSection = styled.section`
    height: auto;
    background-size: cover;
    position: relative;

    .gatsby-image-wrapper {
      &:before {
        content: '';
        position: absolute;
        height: 104%;
        width: 100%;
        background: #000;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.5;
      }
    }
  `
  const HeroHeading = styled.h1`
    position: relative;
    @media (min-width: 767px) {
      &:before {
        content: '';
        height: 3px;
        width: 50px;
        position: absolute;
        top: -0.5em;
      }
    }
  `

  const FormWrapper = styled.div`
    margin-top: 2em;
    @media (max-width: 767px) {
      margin-bottom: ${FORMOFFSETPX * -1}px;
    }
  `

  const HeroRating = styled.div`
    color: white;
    margin-top: 40px;

    font-weight: 600;
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 20px;
    }
  `

  const HeroRatingStars = styled.div`
    svg {
      fill: #ffbc00;
      stroke: none;
    }
  `

  const HeroSectionOffset = styled.div`
    @media (max-width: 767px) {
      height: ${FORMOFFSETPX - 20}px;
    }
  `

  return (
    <>
      <HeroSection
        className={'hero-Stacked border-color-secondary service-hero'}
      >
        <Img fluid={pageData.heroImage.fluid} className="heroAreaImg" />
        <ContentContainer className="container">
          <ContentRow className="row">
            <ContentCol className="col-lg-6 col-md-6">
              <HeroHeading className="before:background-secondary">
                {pageData.serviceTitle}
              </HeroHeading>
              <p>{pageData.excerpt}</p>

              <HeroRating>
                <HeroRatingStars>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </HeroRatingStars>
                <span
                  className="rating-desc"
                  itemScope=""
                  itemType="http://schema.org/Product"
                >
                  <span itemProp="name">Cali's Finest Landscaping</span>
                  <span
                    itemProp="aggregateRating"
                    itemScope=""
                    itemType="http://schema.org/AggregateRating"
                  >
                    {' '}
                    rated <span itemProp="ratingValue">4.9</span>/5 <br />
                    based on <span itemProp="reviewCount">
                      79
                    </span> reviews.{' '}
                  </span>
                </span>
              </HeroRating>
            </ContentCol>
            <div className="col-lg-2 d-none d-lg-block" />
            <FormWrapper className="col-lg-4 col-md-6">
              <CardGeneral>
                <HeadingThree>
                  {'FREE ' + pageData.serviceTitle + ' Quote'}
                </HeadingThree>
                <p>Schedule a Free Consultation Today!</p>

                <ContactForm
                  style={'minimal-hero'}
                  showPlaceHolders={true}
                  showLabels={false}
                />
              </CardGeneral>
            </FormWrapper>
          </ContentRow>
        </ContentContainer>
      </HeroSection>
      <HeroSectionOffset />
    </>
  )
}

export default ServiceHero
